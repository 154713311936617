import {FetchWs} from "@/functions/FetchWs";
import {ClientesInterfaces} from "@/pages/painel/clientes/ClientesInterfaces";
import FormStruct from "./ClientesUpdateFormStruct";

export async function getClientes(vue: any): Promise<void>
{
    vue.items = [];
    const response = await FetchWs('user/get-all');
    if(response && response.success && response.data.length > 0){
        vue.items = assertClientes(response.data);
    }
}

function assertClientes(objs: ClientesInterfaces[]): ClientesInterfaces[]
{
    objs.forEach((value:ClientesInterfaces,key:number) => {
        objs[key].download_id = value.usu_id
        objs[key].visualizar_id = value.usu_id
        objs[key].delete_id = value.usu_id
        objs[key].senha_id = value.usu_id
        objs[key].editar_id = value.usu_id
        objs[key].foto_id = value.usu_id
    })
    return objs;
}


export async function submitFormNew(data: any, vue: any)
{
    const body = new FormData();
    body.append("nome", data.register_name);
    body.append("email", data.register_email);
    body.append("senha", data.register_pass);
    body.append("url_nfs", data.register_url_servicos);
    body.append("cnpj", data.register_cnpj);
    const response = await FetchWs('auth/register', 'POST',body);

    if(response?.success) {
        vue.$router.back()
    }
}
export async function submitFormUpdate(data: any, vue: any)
{
    const body = new FormData();
    body.append("nome", data.register_name);
    body.append("email", data.register_email);
    body.append("url_nfs", data.register_url_servicos);
    body.append("cnpj", data.register_cnpj);
    const response = await FetchWs('user/update/' + vue.id, 'POST',body);

    if(response?.success) {
        vue.$router.back()
    }
}

export async function updatePass(data: any, vue: any)
{
    const body = new FormData();
    body.append("id", vue.id);
    body.append("senha", data.register_pass);
    const response = await FetchWs('auth/changePass', 'POST',body);

    if(response?.success) {
        vue.$router.back()
    }
}

export async function updateFoto(data: any, vue: any)
{
    const body = new FormData();
    body.append("id", vue.id);
    body.append("foto", data.usu_foto[0]);
    const response = await FetchWs('user/update-foto', 'POST',body);

    if(response?.success) {
        vue.$router.back()
    }
}

export async function deleteCliente(id: number, vue: any)
{
    const response = await FetchWs(`user/delete/${id}`, 'DELETE');
    if(response && response.success){
        await getClientes(vue)
    }
}

export async function getCliente(id: number): Promise<any>
{
    const response = await FetchWs('user/get/' + id);
    if(response && response.success){
        return response.data
    } else {
        return false;
    }
}

export async function carregaDadosParaFormUpdate(vue: any){
    const cliente = await getCliente(vue.id)
    if (!cliente) {
        vue.$router.back()
    } else {
        const struct = FormStruct;
        struct[0][0].value = cliente.usu_name
        struct[1][0].value = cliente.usu_cnpj
        struct[2][0].value = cliente.usu_url_servicos
        struct[3][0].value = cliente.usu_email
        vue.form.struct = struct
        vue.formCarregado++
    }
}