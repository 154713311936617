export default {
    columns: [
        { key: 'bal_ano', label: 'Ano', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'visualizar_id', label: 'visualizar', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'download_id', label: 'Baixar', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',}
    ],
    columnsAdmin: [
        { key: 'bal_ano', label: 'Ano', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'visualizar_id', label: 'visualizar', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'download_id', label: 'Baixar', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'delete_id', label: 'Excluir', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',}
    ]
}