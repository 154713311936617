<template>
  <div class="row justify--center">
    <div class="flex xs12 sm12 md12 lg6 xl9">
      <va-card  class="flex mb-4" title="Minhas contabilidade">
        <va-card-title>
          <div style="text-align: center; width: 100%">
            <va-button v-if="!$Auth.isAdmin()" icon-right="add_circle" color="secondary" gradient @click="solicitarBalanco()">
              Solicitar
            </va-button>
            <va-button v-else icon-right="add_circle" color="secondary" gradient  @click="$router.push({ name: 'admin-contabilidade-new' });">
              Enviar balanço
            </va-button>
          </div>
        </va-card-title>
        <va-card-content>
          <va-alert v-if="selectedItems.length > 0" class="mt-3" border="top">
            <span>
              Itens Selecionados:
              <va-chip class="ml-2">
                {{ selectedItems.length }}
              </va-chip>
              <va-button class="mr-2 mb-2 download-multiplo" @click="downloadMultiploContabilidade(selectedItems)"> Download</va-button>
            </span>
          </va-alert>

          <va-data-table 
            :no-data-html="'Nenhum balanço'"
            :items="items" 
            :columns="columns"
            :selectable="selectable"
            v-model="selectedItems"
            :animated="true" 
            :hoverable="true" 
            :striped="true"
          >
            <template>
              <va-checkbox class="flex mb-2 md4" label="" v-model="selectable" />
            </template>
            <template #cell(visualizar_id)="{ source: visualizar_id }">
              <va-button icon="picture_as_pdf" gradient @click="visualizarBalanco(visualizar_id, this)"></va-button>
            </template>
            <template #cell(download_id)="{ source: download_id }">
              <va-button icon="cloud_download" gradient @click="downloadBalanco(download_id)" download="Fuzhu-contabilidade"></va-button>
            </template>
            <template #cell(compartilhar_id)="{ source: compartilhar_id }">
              <va-button class="mr-4" @click="showModal = !showModal">
                Show modal size small
              </va-button>
              <slot>
                <va-button class="mr-4" @click="compartilharBalanco(compartilhar_id)">
                  Show modal size small
                </va-button>
              </slot>
            </template>
            <template #cell(delete_id)="{ source: delete_id }">
              <va-button icon-right="autorenew" color="danger" gradient @click="deleteBalanco(delete_id, this)">Excluir</va-button>
            </template>
          </va-data-table>
        </va-card-content>
      </va-card>
    </div>

    <div :class="{fundoPdf: pdfBase64}"></div>
    <PdfViewer v-if="pdfBase64 !== false" filePdf="pdfBase64" @closePdf="closePdf()" >
      <template v-slot:main >
        <embed :src="pdfBase64" width= "100%" height= "100%">
      </template>
    </PdfViewer>

  </div>
</template>

<script>
import ContabilidadeTable from "./ContabilidadeTable";
import {
  deleteBalanco,
  downloadBalanco,
  downloadMultiploContabilidade,
  getBalancos,
  solicitarBalanco,
  visualizarBalanco
} from './ContabilidadeFunctions';
import PdfViewer from "@/pages/painel/pdf/PdfViewer.vue";

export default {
  name: "Contabilidade",
  async mounted() {
    await getBalancos(this);
  },
  data() {
    return {
      columns: this.$Auth.isAdmin() ?ContabilidadeTable.columnsAdmin : ContabilidadeTable.columns,
      items: [],
      pdfBase64: false,
      pdfView: false,
      selectable: true,
      selectedItems: [],
      showModal: false,
    }
  },
  methods: {
    deleteBalanco,
    downloadBalanco,
    downloadMultiploContabilidade,
    solicitarBalanco,
    visualizarBalanco,
    closePdf(){
      this.pdfBase64 = false
    }
  },
  components:{
    PdfViewer
  }
}
</script>

<style scoped>
.download-multiplo{
  float: right;
}
</style>