import {FaturaInterface} from "@/pages/painel/faturas/FaturasInterfaces";
import {FetchWs} from "@/functions/FetchWs";
import {base64ArrayFilesToZipAndDownload, downloadBase64File} from "@/functions/FileFunctions";
import {capitalizeFirstLetter, formatDate} from "@/functions/Formatters";

export async function downloadFatura(id: number): Promise<void>
{
    const response = await FetchWs('faturas/get-fatura/' + id);
    if(response && response.success && response.data && response.data.base64){
        let nome = capitalizeFirstLetter(formatDate(response.data.fat_data_emissao, 'YYYY-MM-DD', 'MMMM [de] YYYY'))
        nome += "."
        nome += response.data.fat_boleto_mimetype.split('/')[1]
        downloadBase64File(response.data.base64,nome)
    }
}

export async function downloadMultiploFaturas(faturas: Array<any>): Promise<void>
{
    const fat_ids: string[] = faturas.map(fat => `ids[]=${fat.fat_id}&`)
    const response = await FetchWs('faturas/get-faturas?' + fat_ids.join(''));
    if (response && response.success && Array.isArray(response.data)) {
        base64ArrayFilesToZipAndDownload(response.data.map((e: any) => {return {
            name: e.nome,
            base64: e.base64,
        }}),'honorarios.zip');
    }
}

export async function getFaturas(vue: any): Promise<void>
{
    vue.items = [];
    const response = await FetchWs('faturas/get-all');
    if(response && response.success && response.data.length > 0){
        vue.items = assertItems(response.data);
    }
}

function assertItems(objs: FaturaInterface[]): FaturaInterface[]
{
    objs.forEach((value,key) => {
        objs[key].download_id = value.fat_id
        objs[key].atualizacao_id = value.fat_id
        objs[key].visualizar_id = value.fat_id
        objs[key].marcar_id = value
    })
    return objs;
}

export async function solicitarAtualizacao(id: number): Promise<void> {
    await FetchWs('faturas/solicitar-atualizacao/' + id, 'POST');
}

export async function visualizarFatura(id: number, vue: any): Promise<void>
{
    const response = await FetchWs('faturas/get-fatura/' + id + '?visualizar=true');
    if(response && response.success && response.data && response.data.base64){
        vue.pdfBase64 = response.data.base64
    }
}

export async function deleteFatura(id: number, vue: any)
{
    const response = await FetchWs(`faturas/delete/${id}`, 'DELETE');
    if(response && response.success){
        await getFaturas(vue)
    }
}

export async function changePago(id: number, pago: string, vue: any)
{
    const response = await FetchWs(`faturas/change-pago/${id}/${pago}`, 'PATCH');
    if(response && response.success){
        await getFaturas(vue)
    }
}

export async function submitFormNew(data: any, vue: any)
{
    const body = new FormData();
    body.append("validade", data.fat_validade);
    body.append("emissao", data.fat_emissao);
    body.append("valor", data.fat_valor);
    body.append("pago", data.fat_pago ? '1' : '0');
    body.append("fatura", data.fat_fatura[0])
    const response = await FetchWs('faturas/new', 'POST', body);
    if(response && response.success){
        vue.$router.back();
    }
}