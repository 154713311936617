<template>
  <div class="row justify--center">
    <div class="flex xs12 sm12 md12 lg9 xl9">
      <va-card  class="flex mb-4">
        <va-card-content>
          <h4 class="display-4">Envie um novo documento</h4><br>
          <monta-form :form="form" @complete="(data)=>submitFormNew(data, this)"/>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
import MontaForm from "@/components/form/MontaForm";
import DocumentosNewFormStruct from "./DocumentosNewFormStruct";
import {submitFormNew} from './DocumentosFunctions';
export default {
  name: "SolicitacoesNew",
  components: {MontaForm},
  data() {
    return {
      form: {
        struct: DocumentosNewFormStruct
      }
    }
  },
  methods:{
    submitFormNew
  },
}
</script>

<style scoped>

</style>