import {FetchExternal, FetchWs} from "@/functions/FetchWs";
import {formatMoney} from "@/functions/Formatters";
import {showError} from "@/functions/Error";
import {getDadosFromCnpj} from "@/functions/Cnpj.ws";
import {getCliente} from "@/pages/painel/clientes/ClientesFunctions";

export async function submitUpdateForm(data:any, tipo:string, vue:any){

    const body = new FormData();
    body.append("tipo", tipo);
    body.append("categoria", data.categoria ? data.categoria : 'n/a');
    body.append("corpo", data.solicitacao);
    const response = await FetchWs('solicitacoes/updateDados', 'POST', body);
    if(response && response.success){
        vue.$router.back()
    }
}

export async function getDados(vue: any): Promise<void>
{
    const force_empresa = localStorage.getItem('ADMIN_EMPRESA_ATUAL');
    let cnpj = vue.$Auth.getProp('cnpj')
    if(vue.$Auth.checkPerm('adminForceEmpresa') && force_empresa){
        const cliente = await getCliente(parseInt(force_empresa))
        cnpj = cliente.usu_cnpj
    }

    const response = await getDadosFromCnpj(cnpj);
    if(response && response.cnpj_raiz ){
        vue.dados = response;
        montaFormDados(vue);
        montaFormsAtividades(vue);
        montaFormsSocios(vue);
    } else {
        showError('Erro interno, tente novamente em alguns segundos.')
        vue.$router.back();
    }
}
function montaFormsSocios(vue: any){
    vue.dados.socios.forEach((socio: any) => {
        vue.formsSocios.push({
            data: {
                readonly: true
            },
            struct: [
                [
                    {
                        col: 4  ,
                        name: "Cargo",
                        value: socio.qualificacao_socio?.descricao,
                        type: "text",
                        label: "Cargo",
                        readonly: true
                    },
                    {
                        col: 8  ,
                        name: "nome",
                        value: socio.nome,
                        type: "text",
                        label: "Nome",
                        readonly: true
                    },
                ],
            ]
        })
    })
}
function montaFormsAtividades(vue: any){
    vue.atividades = [
        vue.dados.estabelecimento.atividade_principal,
        ...vue.dados.estabelecimento.atividades_secundarias
    ]
    vue.atividades.forEach((cnae: any) => {
        vue.formsAtividades.push({
            data: {
                readonly: true
            },
            struct: [
                [
                    {
                        col: 3  ,
                        name: "cnae",
                        value: cnae.subclasse,
                        type: "text",
                        label: "CNAE",
                        readonly: true
                    },
                    {
                        col: 9  ,
                        name: "descricao",
                        value: cnae.descricao,
                        type: "text",
                        label: "Descriçao",
                        readonly: true
                    },
                ],
            ]
        })
    })
}
function montaFormDados(vue: any){
    vue.formDados = {
        data: {
            readonly: true
        },
        struct: [
            [
                {
                    col: 6  ,
                    name: "razao_social",
                    value: vue.dados.razao_social,
                    type: "text",
                    label: "Razão Social",
                    readonly: true
                },
                {
                    col: 6  ,
                    name: "nome_fantasia",
                    value: vue.dados.estabelecimento?.nome_fantasia,
                    type: "text",
                    label: "Nome Fantasia",
                    readonly: true
                }
            ],
            [
                {
                    col: 4 ,
                    name: "CNPJ",
                    value: vue.dados.estabelecimento?.cnpj,
                    type: "text",
                    label: "CNPJ",
                    readonly: true,
                    mask:{
                        blocks: [2, 3, 3, 4, 2],
                        delimiters: [".", ".", ".", "-"]
                    }
                },
                {
                    col: 4  ,
                    name: "natureza_juridica",
                    value: vue.dados.natureza_juridica?.descricao,
                    type: "text",
                    label: "Natureza Juridica",
                    readonly: true
                },
                {
                    col: 4 ,
                    name: "porte",
                    value: vue.dados.porte?.descricao,
                    type: "text",
                    label: "Porte da empresa",
                    readonly: true
                }
            ],
            [
                {
                    col: 4 ,
                    name: "inicio_atividade",
                    value: vue.dados.estabelecimento?.data_inicio_atividade,
                    type: "text",
                    label: "Início da atividade",
                    readonly: true
                },
                {
                    col: 4  ,
                    name: "capital_social",
                    value: formatMoney(parseInt(vue.dados.capital_social)),
                    type: "text",
                    label: "Capital Social",
                    readonly: true,
                },
                {
                    col: 4 ,
                    name: "simples",
                    value: vue.dados.simples?.simples ? vue.dados.simples?.simples : 'Não',
                    type: "text",
                    label: "Simples",
                    readonly: true
                }
            ],
            [
                {
                    col: 3 ,
                    name: "telefone",
                    value: '(' + vue.dados.estabelecimento?.ddd1 + ') ' + vue.dados.estabelecimento?.telefone1,
                    type: "text",
                    label: "Telefone",
                    readonly: true
                },
                {
                    col: 3 ,
                    name: "estado",
                    value: vue.dados.estabelecimento?.estado.nome,
                    type: "text",
                    label: "Estado",
                    readonly: true
                },
                {
                    col: 3 ,
                    name: "cidade",
                    value: vue.dados.estabelecimento?.cidade.nome,
                    type: "text",
                    label: "Cidade",
                    readonly: true
                },
                {
                    col: 3 ,
                    name: "bairro",
                    value: vue.dados.estabelecimento?.bairro,
                    type: "text",
                    label: "Bairro",
                    readonly: true
                }
            ],
            [
                {
                    col: 3 ,
                    name: "cep",
                    value: vue.dados.estabelecimento?.cep,
                    type: "text",
                    label: "cep",
                    readonly: true
                },
                {
                    col: 3 ,
                    name: "logradouro",
                    value: vue.dados.estabelecimento?.logradouro,
                    type: "text",
                    label: "Logradouro",
                    readonly: true
                },
                {
                    col: 3 ,
                    name: "numero",
                    value: vue.dados.estabelecimento?.numero,
                    type: "text",
                    label: "Número",
                    readonly: true
                },
                {
                    col: 3 ,
                    name: "Complemento",
                    value: vue.dados.estabelecimento?.complemento,
                    type: "text",
                    label: "Complemento",
                    readonly: true
                }
            ]
        ]
    };
}


// function assertItems(objs: FaturaInterface[]): FaturaInterface[]
// {
//     objs.forEach((value,key) => {
//         objs[key].download_id = value.fat_id
//         objs[key].atualizacao_id = value.fat_id
//         objs[key].visualizar_id = value.fat_id
//         objs[key].marcar_id = value
//     })
//     return objs;
// }
