<template>
  <div class="row justify--center">
    <div class="flex xs12 sm12 md12 lg12 xl12">
      <va-card  class="flex mb-4">
        <va-card-title>
          <div v-if="!$Auth.isAdmin()" style="text-align: center; width: 100%">
            <va-button icon-right="add_circle" color="secondary" gradient @click="$router.push({ name: 'dados-cadastrais-atualizar-empresa' });">
              Alteração cadastral
            </va-button>&nbsp;&nbsp;
            <va-button icon-right="add_circle" color="secondary" gradient @click="$router.push({ name: 'dados-cadastrais-atualizar-socios' });">
              Alteração societária
            </va-button>&nbsp;&nbsp;
            <va-button icon-right="add_circle" color="secondary" gradient @click="$router.push({ name: 'dados-cadastrais-atualizar-outros' });">
              Outras alterações
            </va-button>
          </div>
        </va-card-title>
        <va-card-content>
          <h4 v-if="formDados" class="display-4">Dados Cadastrais</h4><br>
          <monta-form v-if="formDados" :form="formDados"/>
          <br /><br />
          <h4 v-if="formsAtividades.length > 0" class="display-4">Lista de atividades econômicas</h4><br>
          <div v-for="form in formsAtividades" :key="form">
            <monta-form :form="form"/>
          </div>
          <br /><br />
          <h4 v-if="formsSocios.length > 0" class="display-4">Lista de sócios</h4><br>
          <div v-for="form in formsSocios" :key="form">
            <monta-form :form="form"/>
          </div>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
import {
  getDados,
} from './DadosFunctions';
import MontaForm from "../../../components/form/MontaForm";

export default {
  name: "Dados",
  components: {MontaForm},
  async mounted() {
    await getDados(this);
  },
  data() {
    return {
      formDados: false,
      formsAtividades: [],
      formsSocios: [],
      atividades: [],
      dados: false
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.download-multiplo{
  float: right;
}
</style>