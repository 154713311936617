<template>
  <div class="row justify--center">
    <div class="flex xs12 sm12 md12 lg12 xl12">
      <va-card  class="flex mb-4" title="Meus documentos">
        <va-card-title>
          <div v-if="$Auth.isAdmin()" style="text-align: center; width: 100%">
            <va-button icon-right="add_circle" color="secondary" gradient @click="$router.push({ name: 'admin-documentos-new' });">
              Enviar documento
            </va-button>
          </div>
        </va-card-title>
        <va-card-content>
          <va-alert v-if="selectedItems.length > 0" class="mt-3" border="top">
            <span>
              Itens Selecionados:
              <va-chip class="ml-2">
                {{ selectedItems.length }}
              </va-chip>
              <va-button class="mr-2 mb-2 download-multiplo" @click="downloadMultiploDocumentos(selectedItems)"> Download</va-button>
            </span>
          </va-alert>

          <va-data-table 
            :no-data-html="'Nenhum documento'" 
            :items="items" :columns="columns"
            :selectable="selectable"
            v-model="selectedItems"
            :animated="true" 
            :hoverable="true" 
            :striped="true"
          >
            <template>
              <va-checkbox class="flex mb-2 md4" label="" v-model="selectable" />
            </template>
            <template #cell(visualizar_id)="{ source: visualizar_id }">
              <va-button icon="picture_as_pdf" @click="visualizarDocumento(visualizar_id, this)"></va-button>
            </template>
            <template #cell(actions)="{ source: actions }">
              <va-button icon="cloud_download" @click="downloadDocumento(actions, this)" download="Fuzhu-documento"></va-button>
            </template>
            <template #cell(doc_validade)="{ source: doc_validade}">
              {{ doc_validade ? formatDate(doc_validade, 'YYYY-MM-DD','L') : 'n/a' }}
            </template>
            <template #cell(is_valid)="{ source: is_valid }">
              <va-icon v-if="is_valid !== undefined"
                name="thumb_up"
                size="large"
                :color="is_valid ? 'success' : 'danger'"
                :flip="is_valid ? 'off' : 'horizontal'"
              />
              <div v-else>n/a</div>
            </template>
            <template #cell(atualizacao_id)="{ source: atualizacao_id }">
              <va-button v-if="!$Auth.isAdmin()" icon-right="autorenew" color="secondary" gradient @click="solicitarAtualizacao(atualizacao_id)">Solicitar</va-button>
              <va-button v-else icon-right="autorenew" color="danger" gradient @click="deleteDocumento(atualizacao_id, this)">Excluir</va-button>
            </template>
          </va-data-table>
        </va-card-content>
      </va-card>
    </div>

    <div :class="{fundoPdf: pdfBase64}"></div>
    <PdfViewer v-if="pdfBase64 !== false" filePdf="pdfBase64" @closePdf="closePdf()" >
      <template v-slot:main >
        <embed :src="pdfBase64" width= "100%" height= "100%">
      </template>
    </PdfViewer>
  </div>
</template>

<script>
import DocumentosTable from "./DocumentosTable";
import {
  deleteDocumento,
  downloadDocumento,
  downloadMultiploDocumentos,
  getDocumentos, solicitarAtualizacao,
  visualizarDocumento,
} from './DocumentosFunctions';
import {formatDate} from "@/functions/Formatters";
import PdfViewer from "@/pages/painel/pdf/PdfViewer.vue";

export default {
  name: "Documentos",
  async mounted() {
    await getDocumentos(this);
    console.log(this.items)
  },
  data() {
    return {
      columns: this.$Auth.isAdmin() ? DocumentosTable.columnsAdmin : DocumentosTable.columns,
      certificado: false,
      items: [],
      pdfBase64: false,
      pdfView: false,
      selectable: true,
      selectedItems: []
    }
  },
  methods: {
    downloadDocumento,
    solicitarAtualizacao,
    downloadMultiploDocumentos,
    formatDate,
    visualizarDocumento,
    deleteDocumento,
    closePdf(){
      this.pdfBase64 = false
    }
  },
  components:{
    PdfViewer
  }
}
</script>

<style scoped>
.download-multiplo{
  float: right;
}
</style>