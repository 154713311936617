export default {
    columns: [
        { key: 'imp_name', label: 'Documento', sortable: false, alignHead: 'center', verticalAlign: 'middle', },
        { key: 'imp_validade', label: 'Validade', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'imp_valor', label: 'Valor', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'imp_pago', label: 'Pago', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'imp_recibo', label: 'Recibo', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'imp_declaracao', label: 'Declaração', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'imp_imposto', label: 'Imposto', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'imp_parcelamento', label: 'Parcelamento', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'atualizacao_id', label: 'Solicitar Atualização', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
    ],
    columnsAdmin: [
        { key: 'imp_name', label: 'Documento', sortable: false, alignHead: 'center', verticalAlign: 'middle', },
        { key: 'imp_validade', label: 'Validade', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'imp_valor', label: 'Valor', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'imp_pago', label: 'Pago', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'imp_recibo', label: 'Recibo', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'imp_declaracao', label: 'Declaração', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'imp_imposto', label: 'Imposto', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'imp_parcelamento', label: 'Parcelamento', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'update_id', label: 'Deletar', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'deletar_id', label: 'Deletar', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
    ]
}