<template>
  <div class="row justify--center">
    <div class="flex xs12 sm12 md12 lg9 xl9">
      <va-card  class="flex mb-4">
        <va-card-title>
          <div style="text-align: center; width: 100%">
            <va-button icon-right="add_circle" color="secondary" gradient @click="$router.push({ name: $Auth.isAdmin() ? 'admin-movimentacoes-new' : 'movimentacoes-new' });">
              Enviar movimentações
            </va-button>
          </div>
        </va-card-title>
        <va-card-content>
          <va-alert v-if="selectedItems.length > 0" class="mt-3" border="top">
            <span>
              Itens Selecionados:
              <va-chip class="ml-2">
                {{ selectedItems.length }}
              </va-chip>
              <va-button class="mr-2 mb-2 download-multiplo" @click="downloadMultiploMov(selectedItems)"> Download</va-button>
            </span>
          </va-alert>

          <va-data-table 
            :no-data-html="'Nenhuma movimentação'"
            :items="items" :columns="columns"
            :selectable="selectable"
            v-model="selectedItems"
            :animated="true" 
            :hoverable="true" 
            :striped="true"
          >
            <template #cell(download_id)="{ source: download_id }">
              <va-button icon="cloud_download" @click="downloadMov(download_id, this)"></va-button>
            </template>
            <template #cell(deletar_id)="{ source: deletar_id }">
              <va-button icon-right="autorenew" color="danger" gradient @click="deleteMov(deletar_id, this)">Excluir</va-button>
            </template>
            <template #cell(mov_dt_cad)="{ source: fat_data_emissao}">
              {{formatDate(fat_data_emissao, 'YYYY-MM-DD', 'L')}}
            </template>
          </va-data-table>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
import FaturasTable from "./MovimentacoesTable";
import {deleteMov, downloadMov, downloadMultiploMov, getMovs} from './MovimentacoesFunctions';
import {formatDate} from "@/functions/Formatters";

export default {
  name: "Movimentacoes",
  async mounted() {
    await getMovs(this);
  },
  data() {
    return {
      columns: this.$Auth.isAdmin() ? FaturasTable.columnsAdmin : FaturasTable.columns,
      certificado: false,
      items: [],
      selectable: true,
      selectedItems: []
    }
  },
  methods: {
    deleteMov,
    downloadMultiploMov,
    downloadMov,
    formatDate
  }
}
</script>

<style scoped>
.download-multiplo{
  float: right;
}
</style>