
export default {
    formDadosEmpresa: [
        [
            {
                col: 12  ,
                name: "categoria",
                value: "",
                type: "select",
                label: "Categoria",
                options:[
                    'Nome Empresarial', 'Aumento de Capital','Endereço',
                    'Objeto Social (Ramos de Atividade)',
                    'Transformação (LTDA, Empresário Individual, etc.)',
                    'Reenquadramento/Desenquadramento ME/EPP',
                    'Outro'
                ],
                required: true
            },
        ],
        [
            {
                col: 12  ,
                name: "solicitacao",
                value: "",
                type: "textarea",
                placeholder: "Por gentileza, descreva aqui em detalhes a sua solicitação.",
                label: "Descritivo da atualização",
                required: true,
            },
        ],
    ],
    formDadosSocios: [
        [
            {
                col: 12  ,
                name: "categoria",
                value: "",
                type: "select",
                label: "Categoria",
                options:[
                    'Ingresso de Sócio',
                    'Retirada de Sócio',
                    'Endereço',
                    'Designação de Administrador',
                    'Destituição de Administrador',
                    'Designação de Administrador Não-Sócio',
                    'Destituição de Administrador Não-Sócio',
                    'Cessão Cotas Retirantes/Ingressante',
                    'Cessão Cotas Retirante/Remanescentes',
                    'Cessão Cotas Entre Sócios',
                    'Mudança Estado Civil',
                    'Outro'
                ],
                required: true
            },
        ],
        [
            {
                col: 12  ,
                name: "solicitacao",
                value: "",
                type: "textarea",
                placeholder: "Por gentileza, descreva aqui em detalhes a sua solicitação.",
                label: "Descritivo da atualização",
                required: true,
            },
        ],
    ],
    formDadosOutros: [
        [
            {
                col: 12  ,
                name: "solicitacao",
                value: "",
                type: "textarea",
                placeholder: "Por gentileza, descreva aqui em detalhes a sua solicitação.",
                label: "Descritivo da atualização",
                required: true,
            },
        ],
    ]
}
