<template>
  <div class="row justify--center">
    <div class="flex xs12 sm12 md9 lg6 xl6">
      <va-card  class="flex mb-4">
        <va-card-content>
          <h4 class="display-4">Atualizar cliente</h4><br>
          <monta-form v-if="formCarregado" :key="formCarregado" :form="form" @complete="(data)=>submitFormUpdate(data, this)"/>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
import MontaForm from "@/components/form/MontaForm";
import {submitFormUpdate,carregaDadosParaFormUpdate} from './ClientesFunctions';
export default {
  name: "SolicitacoesNew",
  components: {MontaForm},
  props:['id'],
  mounted(){
    if(!this.id){
      this.$router.back()
    }
    this.carregaDadosParaFormUpdate(this)
  },
  data() {
    return {
      form: {},
      formCarregado: 0
    }
  },
  methods:{
    carregaDadosParaFormUpdate,
    submitFormUpdate
  },
}
</script>

<style scoped>

</style>