import {FetchExternal} from "@/functions/FetchWs";

export async function getDadosFromCnpj(cnpj: string): Promise<any>
{
    const currentDate = new Date().getTime();
    let response = JSON.parse(localStorage.getItem(cnpj)??'false');
    if(!response || !response.expire_at || currentDate > response.expire_at) {
        response = await FetchExternal(`https://publica.cnpj.ws/cnpj/${cnpj}`);
        response.expire_at = currentDate + (60000 * 60 * 24 * 5);
    }
    localStorage.setItem(cnpj, JSON.stringify(response))
    return response;
}