import {bytesToMegaBytes} from "@/functions/FileFunctions";

export default [
    [
        {
            col: 12,
            name: "usu_foto",
            value: "",
            type: "va-file-upload",
            label: "Logo",
            multiple: false,
            required: true,
            validateFunc(e: any){
                if(Array.isArray(e) && e.length > 0){
                    if(!e[0].type?.includes('image')){
                        return 'Somente Imagem';
                    }
                    if(bytesToMegaBytes(e[0].size) > 2){
                        return 'Tamanho máximo: 2MB';
                    }
                }

                return true;
            }
        }
    ]
]