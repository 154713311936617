export default {
    columns: [
        { key: 'cer_name', label: 'Documento', sortable: false, alignHead: 'center', verticalAlign: 'middle', },
        { key: 'cer_emissao', label: 'Emissão', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'cer_validade', label: 'Validade', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'is_valid', label: 'Valido', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'visualizar_id', label: 'Visualizar', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'download_id', label: 'Baixar', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        // { key: 'compartilhar_id', label: 'Compartilhar', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'atualizacao_id', label: 'Solicitar Atualização', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
    ],
    columnsAdmin: [
        { key: 'cer_name', label: 'Documento', sortable: false, alignHead: 'center', verticalAlign: 'middle', },
        { key: 'cer_emissao', label: 'Emissão', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'cer_validade', label: 'Validade', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'is_valid', label: 'Valido', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'visualizar_id', label: 'Visualizar', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'download_id', label: 'Baixar', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        // { key: 'compartilhar_id', label: 'Compartilhar', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'atualizacao_id', label: 'Excluir', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
    ]
}