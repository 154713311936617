import {ImpostosInterface} from "@/pages/painel/impostos/ImpostosInterfaces";
import {FetchWs} from "@/functions/FetchWs";
import {downloadBase64File} from "@/functions/FileFunctions";
import DocumentosUpdateFormStruct from "@/pages/painel/impostos/ImpostosUpdateFormStruct";

export async function getImposto(id: number, visualizar = false): Promise<ImpostosInterface|false>
{
    const visualizar_string = visualizar ? '?visualizar=true' : '';
    const response = await FetchWs(`impostos/get-imposto/${id}${visualizar_string}`);
    if(response && response.success && response.data && response.data){
        return response.data;1
    } else {
        return false;
    }
}

export async function downloadImposto(id: number, arquivo: string): Promise<void>
{
    const imposto = await getImposto(id);
    if(imposto !== false){
        if(arquivo === 'recibo' && imposto.base64_recibo){
            downloadBase64File(imposto.base64_recibo,imposto.imp_name + '-recibo.pdf')
        } else if (arquivo === 'declaracao' && imposto.base64_declaracao){
            downloadBase64File(imposto.base64_declaracao,imposto.imp_name + '-declaracao.pdf')
        } else if (arquivo === 'imposto' && imposto.base64_imposto){
            downloadBase64File(imposto.base64_imposto,imposto.imp_name + '-imposto.pdf')
        } else if (arquivo === 'parcelamento' && imposto.base64_parcelamento){
            downloadBase64File(imposto.base64_parcelamento,imposto.imp_name + '-parcelamento.pdf')
        }
    }
}

export async function solicitarAtualizacao(id: number): Promise<void> {
    await FetchWs('impostos/solicitar-atualizacao/' + id, 'POST');
}

export async function getImpostos(vue: any): Promise<void>
{
    vue.items = [];
    const response = await FetchWs('impostos/get-all');
    if(response && response.success && response.data.length > 0){
        vue.items = assertImpostos(response.data);
    }
}

function assertImpostos(objs: ImpostosInterface[]): ImpostosInterface[]
{
    objs.forEach((value:ImpostosInterface,key:number) => {
        objs[key].imp_valor             = value.imp_valor / 100
        objs[key].imp_recibo            = objs[key].imp_recibo ? value.imp_id : 0
        objs[key].imp_imposto           = objs[key].imp_imposto ? value.imp_id : 0
        objs[key].imp_declaracao        = objs[key].imp_declaracao ? value.imp_id : 0
        objs[key].imp_parcelamento      = objs[key].imp_parcelamento ? value.imp_id : 0
        objs[key].atualizacao_id        = value.imp_id
        objs[key].update_id             = value.imp_id
        objs[key].deletar_id            = value.imp_id

    })
    return objs;
}

export async function submitFormNew(data: any, vue: any)
{
    const body = new FormData();
    body.append("nome", data.nome);
    body.append("validade", data.validade);
    body.append("valor", data.valor);
    body.append("pago", data.pago ? '1' : '0');
    if(Array.isArray(data.recibo) && data.recibo.length > 0) {
        body.append("recibo", data.recibo[0])
    }
    if(Array.isArray(data.declaracao) && data.declaracao.length > 0){
        body.append("declaracao", data.declaracao[0])
    }
    if(Array.isArray(data.imposto) && data.imposto.length > 0){
        body.append("imposto", data.imposto[0])
    }
    if(Array.isArray(data.parcelamento) && data.parcelamento.length > 0){
        body.append("parcelamento", data.parcelamento[0])
    }
    const response = await FetchWs('impostos/new', 'POST', body);
    if(response && response.success){
        vue.$router.back();
    }
}export async function submitFormUpdate(data: any, vue: any)
{
    console.log(data)
    const body = new FormData();
    body.append("id", vue.id);
    body.append("nome", data.nome);
    body.append("validade", data.validade);
    body.append("valor", data.valor);
    body.append("pago", data.pago ? '1' : '0');
    body.append("alterar_recibo", data.alterar_recibo ? '1' : '0');
    if(data.alterar_recibo && Array.isArray(data.recibo) && data.recibo.length > 0) {
        body.append("recibo", data.recibo[0])
    }
    body.append("alterar_declaracao", data.alterar_declaracao ? '1' : '0');
    if(data.alterar_declaracao && Array.isArray(data.declaracao) && data.declaracao.length > 0){
        body.append("declaracao", data.declaracao[0])
    }
    body.append("alterar_imposto", data.alterar_imposto ? '1' : '0');
    if(data.alterar_imposto && Array.isArray(data.imposto) && data.imposto.length > 0){
        body.append("imposto", data.imposto[0])
    }
    body.append("alterar_parcelamento", data.alterar_parcelamento ? '1' : '0');
    if(data.alterar_parcelamento && Array.isArray(data.parcelamento) && data.parcelamento.length > 0){
        body.append("parcelamento", data.parcelamento[0])
    }
    const response = await FetchWs('impostos/update', 'POST', body);
    if(response && response.success){
        vue.$router.back();
    }
}

export async function   deleteImposto(id: number, vue: any)
{
    const response = await FetchWs(`impostos/delete/${id}`, 'DELETE');
    if(response && response.success){
        await getImpostos(vue)
    }
}

export async function carregaDadosParaFormUpdate(vue: any){
    const imposto = await getImposto(vue.id, true)
    if (!imposto) {
        vue.$router.back()
    } else {
        const struct = DocumentosUpdateFormStruct;
        struct[0][0].value = imposto.imp_name
        struct[0][1].value = imposto.imp_validade
        struct[0][2].value = imposto.imp_valor.toString()
        struct[0][3].value = imposto.imp_pago ? true : false
        vue.form.struct = struct
        vue.formCarregado++
    }
}