<template>
  <div class="row justify--center">
    <div class="flex xs12 sm12 md12 lg12 xl12 ">
      <va-card  class="flex mb-4 " title="Minhas certidões">
        <va-card-title>
          <div v-if="$Auth.isAdmin()" style="text-align: center; width: 100%">
            <va-button icon-right="add_circle" color="secondary" gradient @click="$router.push({ name: 'admin-certidoes-new' });">
              Enviar certidão
            </va-button>
          </div>
        </va-card-title>
        <va-card-content>
          <va-alert v-if="selectedItems.length > 0" class="mt-3" border="top">
            <span class="card-download">
              <va-chip class="ml-2">
                Itens Selecionados: {{ selectedItems.length }}
              </va-chip>
              <va-button class="download-multiplo" @click="downloadMultiploCertidao(selectedItems)"> Download</va-button>
            </span>
          </va-alert>
          <va-data-table 
            :no-data-html="'Nenhuma certidão'" 
            :items="items" 
            :columns="columns"
            :selectable="selectable"
            v-model="selectedItems"
            @selectionChange="selectedItemsEmitted = $event.currentSelectedItems"
            :animated="true" 
            :hoverable="true" 
            :striped="true"
          >

            <template>
              <va-checkbox class="flex mb-2 md4" label="" v-model="selectable" />
            </template>
            <template #cell(cer_emissao)="{ source: cer_emissao }">
              {{ formatDate(cer_emissao, 'YYYY-MM-DD','L') }}
            </template>
            <template #cell(cer_validade)="{ source: cer_validade }">
              {{ formatDate(cer_validade, 'YYYY-MM-DD','L') }}
            </template>
            <template #cell(is_valid)="{ source: is_valid }">
              <va-icon
                name="thumb_up"
                size="large"
                :color="is_valid ? 'success' : 'danger'"
                :flip="is_valid ? 'off' : 'horizontal'"
              />
            </template>
            <template #cell(visualizar_id)="{ source: visualizar_id }">
              <va-button icon="picture_as_pdf" gradient @click="visualizarCertidao(visualizar_id, this)"></va-button>
            </template>
            <template #cell(download_id)="{ source: download_id }">
              <va-button icon="cloud_download" gradient @click="downloadCertidao(download_id, this)" download="Fuzhu-certidoes"></va-button>
            </template>
            <template #cell(compartilhar_id)="{ source: compartilhar_id }">
              <va-button-dropdown left-icon>
                <va-button icon="whatsapp" gradient @click="compartilharCertidao(compartilhar_id, this)"></va-button>
                <va-button icon="email" gradient @click="compartilharCertidao(compartilhar_id, this)"></va-button>
              </va-button-dropdown>
            </template>
            <template #cell(atualizacao_id)="{ source: atualizacao_id }">
              <va-button v-if="!$Auth.isAdmin()" icon-right="autorenew" color="secondary" gradient @click="solicitarAtualizacao(atualizacao_id, this)">Solicitar</va-button>
              <va-button v-else icon-right="autorenew" color="danger" gradient @click="deleteCertidao(atualizacao_id, this)">Excluir</va-button>
            </template>
          </va-data-table>
        </va-card-content>
      </va-card>

    </div>

    <div :class="{fundoPdf: pdfBase64}"></div>
    <PdfViewer v-if="pdfBase64 !== false" filePdf="pdfBase64" @closePdf="closePdf()" >
      <template v-slot:main >
        <embed :src="pdfBase64" width= "100%" height= "100%">
      </template>
    </PdfViewer>

  </div>
</template>

<script>
import CertidoesTable from "./CertidoesTable";
import {formatDate} from "@/functions/Formatters";
import {
  downloadCertidao,
  downloadMultiploCertidao,
  getCertidoes,
  solicitarAtualizacao,
  visualizarCertidao,
  compartilharCertidao,
  deleteCertidao
} from './CertidoesFunctions';
import PdfViewer from "@/pages/painel/pdf/PdfViewer.vue";

export default {
  name: "Certidoes",
  async mounted() {
    await getCertidoes(this);
  },
  data() {
    return {
      columns: this.$Auth.isAdmin() ? CertidoesTable.columnsAdmin : CertidoesTable.columns,
      items: [],
      pdfBase64: false,
      pdfView: false,
      selectable: true,
      selectedItems: [],
      selectedItemsEmitted: [],
    }
  },
  methods: {
    downloadCertidao,
    downloadMultiploCertidao,
    solicitarAtualizacao,
    formatDate,
    visualizarCertidao,
    compartilharCertidao,
    deleteCertidao,
    closePdf(){
      this.pdfBase64 = false
    },
  },
  components:{
    PdfViewer
  }
}
</script>

<style scoped>
.card-download{
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.download-multiplo{
  float: right;
}
@media only screen and (max-width: 600px){
  .app-layout__page__title h2 {
    padding: 100px 0 150px 70px!important;
  }
}
.va-dropdown__content {
  background: #939393;
}
</style>