<template>
  <div class="row justify--center">
    <div class="flex xs12 sm12 md12 lg9 xl9">
      <va-card  class="flex mb-4">
        <va-card-title>
          <div style="text-align: left; width: 100%">
            <va-button color="secondary" gradient @click="$router.back()">
              Voltar
            </va-button>
          </div>
          <div v-if="files.length > 0" style="text-align: right; width: 100%">
            <va-button color="info" gradient @click="sendNewMov(this)">
              Enviar
            </va-button>
          </div>
        </va-card-title>
        <va-card-content>
          <va-file-upload v-model="files" dropzone />
          <div style="text-align: center; width: 100%; margin-top: 20px">
          </div>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
import { sendNewMov } from './MovimentacoesFunctions';

export default {
  name: "MovimentacoesNewForm",
  mounted(){
    const textoDoArrasESolta = document.getElementsByClassName('va-file-upload__field__text');
    textoDoArrasESolta[0].innerText = 'Solte seu arquivo aqui ou';
    const botaoDeUpload = document.querySelector('.va-file-upload__field .va-button__content');
    botaoDeUpload.innerText = 'Clique para selecionar';
  },
  data () {
    return {
      files: [],
    }
  },
  methods:{
    sendNewMov
  },
  watch: {
    files: function (){
      if(this.files.length > 1){
        this.files = this.files.slice(-1);
      }
    }
  }

}
</script>

<style scoped>

</style>