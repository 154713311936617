<template>
  <div class="row justify--center">
    <div class="flex xs12 sm12 md12 lg6 xl12">
      <va-card  class="flex mb-4" >
        <va-card-title>
          <div style="text-align: center; width: 100%">
            <va-button icon-right="add_circle" color="info" gradient @click="$router.push({name:'admin-clientes-new'})">
              Adicionar Cliente
            </va-button>
          </div>
        </va-card-title>
        <va-card-content>
          <va-data-table 
            :no-data-html="'Nenhum cliente'"
            :items="items" 
            :columns="columns"
            :selectable="selectable"
            v-model="selectedItems"
            :animated="true" 
            :hoverable="true" 
            :striped="true"
          >
            <template #cell(usu_data_cad)="{ source: usu_data_cad }">
              {{ formatDate(usu_data_cad, 'YYYY-MM-DD','L') }}
            </template>
            <template #cell(usu_cnpj)="{ source: usu_cnpj }">
              {{ formatCnpj(usu_cnpj ?? '') }}
            </template>
            <template #cell(delete_id)="{ source: delete_id }">
              <va-button icon-right="autorenew" color="danger" gradient @click="deleteCliente(delete_id, this)">Excluir</va-button>
            </template>
            <template #cell(editar_id)="{ source: editar_id }">
              <va-button icon-right="autorenew" color="secondary" gradient @click="$router.push({ name: 'admin-clientes-update', params: {id: editar_id }});">Editar</va-button>
            </template>
            <template #cell(senha_id)="{ source: senha_id }">
              <va-button icon-right="autorenew" color="secondary" gradient @click="$router.push({ name: 'admin-clientes-update-pass', params: {id: senha_id }});">Trocar Senha</va-button>
            </template>
            <template #cell(foto_id)="{ source: foto_id }">
              <va-button icon-right="autorenew" color="secondary" gradient @click="$router.push({ name: 'admin-clientes-update-logo', params: {id: foto_id }});">Trocar Logo</va-button>
            </template>
          </va-data-table>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
import ContabilidadeTable from "./ClientesTable";
import {formatDate,formatCnpj} from "@/functions/Formatters";
import {
  deleteCliente,
  getClientes,
} from './ClientesFunctions';

export default {
  name: "Contabilidade",
  async mounted() {
    await getClientes(this);
  },
  data() {
    return {
      columns: ContabilidadeTable.columns,
      items: []
    }
  },
  methods: {
    deleteCliente,
    formatDate,
    formatCnpj
  }
}
</script>

<style scoped>
</style>