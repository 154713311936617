import {bytesToMegaBytes} from "@/functions/FileFunctions";
import {moneyMask} from "@/functions/Masks";

export default [
    [
        {
            col: 6  ,
            name: "fat_emissao",
            value: "",
            type: "date",
            label: "Emissão",
            required: true
        },
        {
            col: 6,
            name: "fat_validade",
            value: "",
            type: "date",
            label: "Validade",
            required: true
        }
    ],
    [
        {
            col: 6  ,
            name: "fat_valor",
            value: "",
            type: "text",
            placeholder: "Ex.: R$ 1.050,00",
            label: "Valor",
            required: true,
            mask: moneyMask
        },
        {
            col: 6,
            name: "fat_pago",
            value: false,
            type: "checkbox",
            label: "Honorário Pago"
        }
    ],
    [
        {
            col: 12,
            name: "fat_fatura",
            value: "",
            type: "va-file-upload",
            label: "Documento",
            multiple: false,
            required: true,
            validateFunc(e: any){
                if(Array.isArray(e) && e.length > 0){
                    if(e[0].type !== 'application/pdf'){
                        return 'Somente PDF';
                    }
                    if(bytesToMegaBytes(e[0].size) > 2){
                        return 'Tamanho máximo: 2MB';
                    }
                }

                return true;
            }
        }
    ]
]