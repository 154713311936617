export default {
    columns: [
        { key: 'usu_name', label: 'Nome', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'usu_email', label: 'E-mail', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'usu_cnpj', label: 'Cnpj', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'usu_data_cad', label: 'Cadastro', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'editar_id', label: 'Editar Cliente', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'foto_id', label: 'Trocar Logo', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'senha_id', label: 'Trocar Senha', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'delete_id', label: 'Excluir', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',}
    ]
}