import {FetchWs} from "@/functions/FetchWs";
import {base64ArrayFilesToZipAndDownload, downloadBase64File} from "@/functions/FileFunctions";
import {capitalizeFirstLetter, formatDate} from "@/functions/Formatters";
import {MovimentacoesInterface} from "@/pages/painel/movimentacoes/MovimentacoesInterfaces";

export async function downloadMov(id: number): Promise<void>
{
    const response = await FetchWs('movimentacoes/get-movimentacao/' + id);
    if(response && response.success && response.data && response.data.base64){
        let nome = capitalizeFirstLetter(formatDate(response.data.mov_dt_cad, 'YYYY-MM-DD', 'L'))
        nome += "."
        nome += response.data.extension
        downloadBase64File(response.data.base64,nome)
    }
}

export async function downloadMultiploMov(movs: Array<any>): Promise<void>
{
    const mov_ids: string[] = movs.map(mov => `ids[]=${mov.mov_id}&`)
    const response = await FetchWs('movimentacoes/get-movimentacoes?' + mov_ids.join(''));
    if (response && response.success && Array.isArray(response.data)) {
        base64ArrayFilesToZipAndDownload(response.data.map((e: any) => {return {
            name: e.nome,
            base64: e.base64,
        }}),'movimentações.zip');
    }
}

export async function getMovs(vue: any): Promise<void>
{
    vue.items = [];
    const response = await FetchWs('movimentacoes/get-all');
    if(response && response.success && response.data.length > 0){
        vue.items = assertItems(response.data);
    }
}

function assertItems(objs: MovimentacoesInterface[]): MovimentacoesInterface[]
{
    objs.forEach((value,key) => {
        objs[key].download_id = value.mov_id
        objs[key].deletar_id = value.mov_id
    })
    return objs;
}

export async function sendNewMov(vue: any): Promise<void>
{
    const body = new FormData();
    body.append("arquivo", vue.files[0]);
    const response = await FetchWs('movimentacoes/new', 'POST', body);
    if(response && response.success){
        vue.$router.back();
    }
}

export async function deleteMov(id: number, vue: any): Promise<void>
{
    const response = await FetchWs(`movimentacoes/delete/${id}`, 'DELETE');
    if(response && response.success){
        await getMovs(vue)
    }
}