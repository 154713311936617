import moment from 'moment';

/**
 * https://momentjs.com/
 */
export function formatDate(date:string, inputFormat:string, outputFormat:string): string
{
    return moment(date, inputFormat).format(outputFormat);
}


export function formatMoney(value:string|number): string
{
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatCnpj(cnpj:string):string{
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
}