export default {
    columns: [
        { key: 'mov_dt_cad', label: 'Data de envio', sortable: false, alignHead: 'center', align: 'center' , verticalAlign: 'middle', },
        { key: 'download_id', label: 'Baixar', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
    ],
    columnsAdmin: [
        { key: 'mov_dt_cad', label: 'Data de envio', sortable: false, alignHead: 'center', align: 'center' , verticalAlign: 'middle', },
        { key: 'download_id', label: 'Baixar', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'deletar_id', label: 'Deletar', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
    ],
}