import {bytesToMegaBytes} from "@/functions/FileFunctions";
import {moneyMask} from "@/functions/Masks";

export default [
    [
        {
            col: 6,
            name: "nome",
            value: "",
            type: "text",
            placeholder: "Ex.: Impostos Estaduais de Março",
            label: "Nome",
            required: true
        },
        {
            col: 6,
            name: "validade",
            value: "",
            type: "date",
            label: "Validade",
            required: true
        },
        {
            col: 6,
            name: "valor",
            value: "",
            type: "text",
            placeholder: "Ex.: R$ 1.050,00",
            label: "Valor",
            required: true,
            mask: moneyMask
        },
        {
            col: 6,
            name: "pago",
            value: false,
            type: "checkbox",
            label: "Pago"
        }
    ],
    [
        {
            col: 12,
            name: "recibo",
            value: "",
            type: "va-file-upload",
            label: "Recibo",
            multiple: false,
            validateFunc(e: any){
                if(Array.isArray(e) && e.length > 0){
                    if(e[0].type !== 'application/pdf'){
                        return 'Somente PDF';
                    }
                    if(bytesToMegaBytes(e[0].size) > 2){
                        return 'Tamanho máximo: 2MB';
                    }
                }

                return true;
            }
        }
    ],
    [
        {
            col: 12,
            name: "declaracao",
            value: "",
            type: "va-file-upload",
            label: "Declaração",
            multiple: false,
            validateFunc(e: any){
                if(Array.isArray(e) && e.length > 0){
                    if(e[0].type !== 'application/pdf'){
                        return 'Somente PDF';
                    }
                    if(bytesToMegaBytes(e[0].size) > 2){
                        return 'Tamanho máximo: 2MB';
                    }
                }

                return true;
            }
        }
    ],
    [
        {
            col: 12,
            name: "imposto",
            value: "",
            type: "va-file-upload",
            label: "Imposto",
            multiple: false,
            validateFunc(e: any){
                if(Array.isArray(e) && e.length > 0){
                    if(e[0].type !== 'application/pdf'){
                        return 'Somente PDF';
                    }
                    if(bytesToMegaBytes(e[0].size) > 2){
                        return 'Tamanho máximo: 2MB';
                    }
                }

                return true;
            }
        }
    ],
    [
        {
            col: 12,
            name: "parcelamento",
            value: "",
            type: "va-file-upload",
            label: "Parcelamento",
            multiple: false,
            validateFunc(e: any){
                if(Array.isArray(e) && e.length > 0){
                    if(e[0].type !== 'application/pdf'){
                        return 'Somente PDF';
                    }
                    if(bytesToMegaBytes(e[0].size) > 2){
                        return 'Tamanho máximo: 2MB';
                    }
                }

                return true;
            }
        }
    ]
]