<template>
  <div id="containerPdf">
    <div class="headerPdf"><span>Visualizador de PDF</span><span id="closePdf" @click="closePdf">X</span></div>
    <slot name="main"></slot>
    <div class="footerPdf"><span>Fuzhu</span></div>
  </div>
</template>

<script>
export default {
  name: 'PdfViewer',
  methods: {
    closePdf(){
      this.$emit('closePdf', true)
    }
  }
}
</script>

<style>
#containerPdf {
  position: fixed;
  z-index: 9;
  top: 7%;
  left: 15%;
}
.headerPdf {
  display: flex;
  justify-content: space-between;
  background: #0c3934;
  color: white;
  padding-left: 10px;
  align-items: center;
  /*cursor: all-scroll;*/
}
embed {
  width: 70vw;
  height: 80vh;
}
#closePdf {
  cursor: context-menu;
  padding: 5px;
  background: red;
}
.footerPdf{
  background: #0c3934;
  color: white;
  text-align: center;
  padding: 5px 0;
}
.fundoPdf{
  background: rgba(0,0,0,0.5);
  width: 100vw;
  height: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
}
</style>