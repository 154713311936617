<template>
  <div class="row justify--center">
    <div class="flex xs12 sm12 md12 lg9 xl9">
      <va-card  class="flex mb-4 " title="Meus Certificados">
        <va-card-title>
          <div v-if="$Auth.isAdmin()" style="text-align: center; width: 100%">
            <va-button icon-right="add_circle" color="secondary" gradient @click="$router.push({ name: 'admin-certificado-digital-new' });">
              Enviar Certificado Digital
            </va-button>
          </div>
        </va-card-title>
        <va-card-content>
          <va-card-actions class="certificados-mobile" align="between" v-if="certificado === false">
            <h4 class="display-4">Carregando...</h4>
          </va-card-actions>
          <va-card-actions class="certificados-mobile" align="between" v-else-if="certificado === 'n/a'">
            <h4 class="display-4">Você não possui certificado digital</h4>
            <va-button icon-right="add_circle" color="secondary" gradient @click="solicitarCertificado()">
              Solicitar novo
            </va-button>
          </va-card-actions>
          <va-card-actions class="certificados-mobile" align="between" v-else-if="certificado.is_valid ?? false">
            <h4 class="display-4">Seu certificado digital está válido</h4>
            <span class="validade-cert">Certificado valido até: {{ formatDate(certificado.dig_validade, 'YYYY-MM-DD','L') }}</span>
            <va-button icon-right="cloud_download" color="primary" gradient @click="downloadCertificado()">
              Baixar
            </va-button>
          </va-card-actions>
          <va-card-actions align="between" v-else>
            <h4 class="display-4">Seu certificado digital expirou</h4>
            <va-button icon-right="add_circle" color="secondary" gradient @click="solicitarCertificado()">
              Solicitar novo
            </va-button>
          </va-card-actions>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
import {downloadCertificado, getCertificado, solicitarCertificado} from './CertificadosFunctions';
import {formatDate} from "@/functions/Formatters";

export default {
  name: "Certificado",
  async mounted() {
    await getCertificado(this);
  },
  data() {
    return {
      certificado: false
    }
  },
  methods: {
    downloadCertificado,
    solicitarCertificado,
    formatDate
  }
}
</script>

<style scoped>
span.validade-cert{
  position: absolute;
  display: block;
  margin-top: 3rem;
  color: #838383;
}
@media only screen and (max-width: 600px) {
  .certificados-mobile {
    flex-direction: column!important;
  }
  span.validade-cert{
    position: relative;
    display: block;
    margin: 1rem 0;
  }
  .display-4 {
    text-align: center;
  }
}
</style>