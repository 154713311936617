// https://github.com/nosir/cleave.js/blob/master/doc/options.md

import {isString} from "@/functions/Validations";

export function phoneMask(value = '') {
    if (!isString(value)) {
        return false;
    }
    if (value.length > 10) {
        return {
            numericOnly: true,
            blocks: [0, 2, 0, 1, 4, 4],
            delimiters: ["(", ")", " ", " ", "-"]
        }
    } else {
        return {
            numericOnly: true,
            blocks: [0, 2, 0, 4, 5],
            delimiters: ["(", ")", " ", "-"]
        }
    }
}

export function moneyMask(value = '') {
    if (!isString(value)) {
        return false;
    }
    let blocks: number[] = [];
    let delimiters: string[] = [];
    if (value.length < 3) {
        blocks = [0,3];
        delimiters = ["R$ "]
    } else  {
        let arrayKey = 0;
        blocks[arrayKey] = 0;
        for (let i = 1; i <= value.length; i++){
            blocks[arrayKey]++;
            if (i === 3 || blocks[arrayKey] === 4) {
                blocks[arrayKey] = 3;
                arrayKey++;
                blocks[arrayKey] = 1;
            }
        }
        delimiters = ["R$ "]
        blocks.forEach((value,key) => delimiters.push(key === blocks.length - 2 ? ',' : '.'))
        blocks.push(0)
        blocks = blocks.reverse()
    }
    return {
        blocks: blocks,
        delimiters: delimiters,
        // numericOnly: true
    }
}

export function cnpjMask(value = '') {
    if (!isString(value)) {
        return false;
    }

    return {
        blocks: [2,3,3,4,2],
        delimiters: ['.','.','.','-'],
        numericOnly:  true
    }
}