import {CertidoesInterface} from "@/pages/painel/certidoes/CertidoesInterfaces";
import {FetchWs} from "@/functions/FetchWs";
import {base64ArrayFilesToZipAndDownload, downloadBase64File} from "@/functions/FileFunctions";

export async function downloadCertidao(id: number): Promise<void> {
    const response = await FetchWs('certidoes/get-certidao/' + id);
    if (response && response.success && response.data && response.data.base64) {
        downloadBase64File(response.data.base64, response.data.cer_name)
    }
}

export async function downloadMultiploCertidao(certidoes: Array<any>): Promise<void> {
    const cert_ids: string[] = certidoes.map(cer => `ids[]=${cer.cer_id}&`)
    const response = await FetchWs('certidoes/get-certidoes?' + cert_ids.join(''));
    if (response && response.success && Array.isArray(response.data)) {
        base64ArrayFilesToZipAndDownload(response.data.map((e: any) => {return {
            name: e.nome,
            base64: e.base64,
        }}),'certidoes.zip');
    }
}

export async function solicitarAtualizacao(id: number): Promise<void> {
    await FetchWs('certidoes/solicitar-atualizacao/' + id, 'POST');
}

export async function getCertidoes(vue: any): Promise<void>
{
    vue.items = [];
    const response = await FetchWs('certidoes/get-all');
    if(response && response.success && response.data.length > 0){
        vue.items = assertCertidoes(response.data);
    }
}

export async function visualizarCertidao(id: number, vue: any): Promise<void> {
    const response = await FetchWs('certidoes/get-certidao/' + id + '?visualizar=true');
    if (response && response.success && response.data && response.data.base64) {
        vue.pdfBase64 = response.data.base64
    }
}

export async function compartilharCertidao(id: number, vue: any): Promise<void> {
    console.log(id);
    // const response = await FetchWs('certidoes/get-certidao/' + id + '?visualizar=true');
    // if (response && response.success && response.data && response.data.base64) {
    //     vue.pdfBase64 = response.data.base64
    // }
}

function checkIfIsValid(cer_validade: string|false)
{
    if(!cer_validade) {return false;}
    const date = new Date(cer_validade);
    const today = new Date();
    today.setHours(0,0,0,0);
    return date >= today;
}

function assertCertidoes(objs: CertidoesInterface[]): CertidoesInterface[]
{
    objs.forEach((value:CertidoesInterface,key:number) => {
        objs[key].download_id = value.cer_id
        objs[key].visualizar_id = value.cer_id
        objs[key].atualizacao_id = value.cer_id
        // objs[key].compartilhar_id = value.cer_id
        objs[key].is_valid = checkIfIsValid(value.cer_validade ?? false)
    })
    return objs;
}


export async function submitFormNew(data: any, vue: any)
{
    const body = new FormData();
    body.append("nome", data.cer_nome);
    body.append("validade", data.cer_validade);
    body.append("emissao", data.cer_emissao);
    body.append("certidao", data.cer_certidao[0])
    const response = await FetchWs('certidoes/new', 'POST', body);
    if(response && response.success){
        vue.$router.back();
    }
}


export async function deleteCertidao(id: number, vue: any)
{
    const response = await FetchWs(`certidoes/delete/${id}`, 'DELETE');
    if(response && response.success){
        await getCertidoes(vue)
    }
}