<template>
  <div class="row justify--center">
    <div class="flex xs12 sm12 md12 lg12 xl12">
      <va-card  class="flex mb-4">
        <va-card-content>
          <div class="row">
            <div class="flex md2" v-if="logo">
              <va-image contain :src="logo" >
                <template #error>
                  Sem logo cadastrada! :(
                </template></va-image>
            </div>
            <div :class="'flex md' + (logo ? '10' : '12')">
              <div class="item">
                <va-card stripe stripe-color="success">
                  <va-card-title>Dados</va-card-title>
                  <va-card-content>
                    <monta-form :form="formDados"/>
                  </va-card-content>
                </va-card>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="flex md6">
              <div class="item">
                <va-card stripe stripe-color="success">
                  <va-card-title>Honorários</va-card-title>
                  <va-card-content>
                    <va-data-table
                      :no-data-html="'Nenhum honorário'"
                      :items="faturasTable.items"
                      :columns="faturasTable.columns"
                      :animated="true"
                      :hoverable="true"
                      :striped="true"
                    >
                      <template #cell(fat_data_vencimento)="{ source: fat_data_vencimento}">
                        {{ formatDate(fat_data_vencimento, 'YYYY-MM-DD','L') }}
                      </template>
                      <template #cell(fat_data_emissao)="{ source: fat_data_emissao}">
                        {{capitalizeFirstLetter(formatDate(fat_data_emissao, 'YYYY-MM-DD', 'MMMM [de] YYYY'))}}
                      </template>
                      <template #cell(fat_valor)="{ source: fat_valor}">
                        {{formatMoney(fat_valor / 100)}}
                      </template>
                      <template #cell(fat_pago)="{ source: fat_pago }">
                        <va-icon
                          name="thumb_up"
                          size="large"
                          :color="fat_pago ? 'success' : 'danger'"
                          :flip="fat_pago ? 'off' : 'horizontal'"
                        />
                      </template>
                    </va-data-table>
                  </va-card-content>
                </va-card>
              </div>
            </div>
            <div class="flex md6">
              <div class="item">
                <va-card stripe stripe-color="success">
                  <va-card-title>Impostos</va-card-title>
                  <va-card-content>
                    <va-data-table
                      :no-data-html="'Nenhum imposto'"
                      :items="impostosTable.items"
                      :columns="impostosTable.columns"
                      :animated="true"
                      :hoverable="true"
                      :striped="true"
                    >

                      <template #cell(imp_validade)="{ source: imp_validade }">
                        {{ formatDate(imp_validade, 'YYYY-MM-DD','L') }}
                      </template>
                      <template #cell(imp_valor)="{ source: imp_valor }">
                        {{ formatMoney(imp_valor)  }}
                      </template>
                      <template #cell(imp_pago)="{ source: imp_pago }">
                        <va-icon
                          name="thumb_up"
                          size="large"
                          :color="imp_pago ? 'success' : 'danger'"
                          :flip="imp_pago ? 'off' : 'horizontal'"
                        />
                      </template>
                    </va-data-table>
                  </va-card-content>
                </va-card>
              </div>
            </div>
          </div>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
import ImpostosTable from "./ImpostosTable";
import FaturasTable from "./FaturasTable";
import {getDados, getFaturas, getImpostos, getLogo} from "./DashboardFunctions";
import {capitalizeFirstLetter,formatDate, formatMoney} from "@/functions/Formatters";
import MontaForm from "../../../components/form/MontaForm";
export default {
  name: "SolicitacoesNew",
  async mounted(){
    await Promise.allSettled([
      getLogo(this),
      getImpostos(this),
      getFaturas(this),
      getDados(this)
    ])
  },
  data() {
    return {
      dados: {},
      logo: false,
      formDados: {},
      impostosTable: {
        columns: ImpostosTable,
        items: []
      },
      faturasTable: {
        columns: FaturasTable,
        items: []
      }
    }
  },
  methods:{
    formatDate,
    formatMoney,
    capitalizeFirstLetter
  },
  components:{MontaForm}
}
</script>

<style scoped>

</style>