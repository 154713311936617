import {bytesToMegaBytes} from "@/functions/FileFunctions";

export default [
    [
        {
            col: 12,
            name: "cer_nome",
            value: "",
            type: "text",
            placeholder: "Ex.: Inscrição estadual",
            label: "Nome",
            required: true
        },
    ],
    [
        {
            col: 6,
            name: "cer_emissao",
            value: "",
            type: "date",
            label: "Emissão",
            required: true
        },
        {
            col: 6,
            name: "cer_validade",
            value: "",
            type: "date",
            label: "Validade",
            required: true
        }
    ],
    [
        {
            col: 12,
            name: "cer_certidao",
            value: "",
            type: "va-file-upload",
            label: "Certidão",
            multiple: false,
            required: true,
            validateFunc(e: any){
                if(Array.isArray(e) && e.length > 0){
                    if(e[0].type !== 'application/pdf'){
                        return 'Somente PDF';
                    }
                    if(bytesToMegaBytes(e[0].size) > 2){
                        return 'Tamanho máximo: 2MB';
                    }
                }

                return true;
            }
        }
    ]
]