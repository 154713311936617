import {bytesToMegaBytes} from "@/functions/FileFunctions";

export default [
    [
        {
            col: 12,
            name: "cer_validade",
            value: "",
            type: "date",
            label: "Validade",
            required: true
        }
    ],
    [
        {
            col: 12,
            name: "cer_certificado",
            value: "",
            type: "va-file-upload",
            label: "Certificado",
            multiple: false,
            required: true,
            validateFunc(e: any){
                if(Array.isArray(e) && e.length > 0){
                    if(bytesToMegaBytes(e[0].size) > 2){
                        return 'Tamanho máximo: 10MB';
                    }
                }

                return true;
            }
        }
    ]
]