<template>
  <div class="row justify--center">
    <div class="flex xs12 sm12 md12 lg12 xl12">
      <va-card  class="flex mb-4" >
        <va-card-title>
          <div v-if="$Auth.isAdmin()" style="text-align: center; width: 100%">
            <va-button icon-right="add_circle" color="secondary" gradient @click="$router.push({ name: 'admin-faturas-new' });">
              Enviar honorário
            </va-button>
          </div>
        </va-card-title>
        <va-card-content>
          <va-alert v-if="selectedItems.length > 0" class="mt-3" border="top">
            <span>
              Itens Selecionados:
              <va-chip class="ml-2">
                {{ selectedItems.length }}
              </va-chip>
              <va-button class="mr-2 mb-2 download-multiplo" @click="downloadMultiploFaturas(selectedItems)"> Download</va-button>
            </span>
          </va-alert>

          <va-data-table 
            :no-data-html="'Nenhum honorário'"
            :items="items" :columns="columns"
            :selectable="selectable"
            v-model="selectedItems"
            :animated="true" 
            :hoverable="true" 
            :striped="true"
          >
            <template #cell(download_id)="{ source: download_id }">
              <va-button icon="cloud_download" @click="downloadFatura(download_id, this)"></va-button>
            </template>
            <template #cell(visualizar_id)="{ source: visualizar_id }">
              <va-button icon="picture_as_pdf" @click="visualizarFatura(visualizar_id, this)"></va-button>
            </template>
            <template #cell(fat_data_vencimento)="{ source: fat_data_vencimento}">
              {{ formatDate(fat_data_vencimento, 'YYYY-MM-DD','L') }}
            </template>
            <template #cell(fat_data_emissao)="{ source: fat_data_emissao}">
              {{capitalizeFirstLetter(formatDate(fat_data_emissao, 'YYYY-MM-DD', 'MMMM [de] YYYY'))}}
            </template>
            <template #cell(fat_valor)="{ source: fat_valor}">
              {{formatMoney(fat_valor / 100)}}
            </template>
            <template #cell(fat_pago)="{ source: fat_pago }">
              <va-icon
                name="thumb_up"
                size="large"
                :color="fat_pago ? 'success' : 'danger'"
                :flip="fat_pago ? 'off' : 'horizontal'"
              />
            </template>
            <template #cell(atualizacao_id)="{ source: atualizacao_id }">
              <va-button v-if="!$Auth.isAdmin()" icon-right="autorenew" color="secondary" gradient @click="solicitarAtualizacao(atualizacao_id, this)">Solicitar</va-button>
              <va-button v-else icon-right="autorenew" color="danger" gradient @click="deleteFatura(atualizacao_id, this)">Excluir</va-button>
            </template>
            <template #cell(marcar_id)="{ source: marcar_id }">
              <va-button v-if="!marcar_id.fat_pago" icon-right="autorenew" color="success" gradient @click="changePago(marcar_id.fat_id, '1', this)">Marcar como Pago</va-button>
              <va-button v-else icon-right="autorenew" color="secondary" gradient @click="changePago(marcar_id.fat_id, '0', this)">Marcar como não pago</va-button>
            </template>
          </va-data-table>
        </va-card-content>
      </va-card>
    </div>

    <div :class="{fundoPdf: pdfBase64}"></div>
    <PdfViewer v-if="pdfBase64 !== false" filePdf="pdfBase64" @closePdf="closePdf()" >
      <template v-slot:main >
        <embed :src="pdfBase64" width= "100%" height= "100%">
      </template>
    </PdfViewer>
  </div>
</template>

<script>
import FaturasTable from "./FaturasTable";
import {
  downloadFatura,
  getFaturas,
  downloadMultiploFaturas,
  solicitarAtualizacao,
  visualizarFatura, deleteFatura, changePago
} from './FaturasFunctions';
import {formatDate, capitalizeFirstLetter, formatMoney} from "@/functions/Formatters";
import PdfViewer from "@/pages/painel/pdf/PdfViewer.vue";

export default {
  name: "Faturas",
  async mounted() {
    await getFaturas(this);
  },
  data() {
    return {
      columns: this.$Auth.isAdmin() ? FaturasTable.columnsAdmin : FaturasTable.columns,
      certificado: false,
      items: [],
      selectable: true,
      selectedItems: [],
      pdfBase64: false,
      pdfView: false,
    }
  },
  methods: {
    changePago,
    deleteFatura,
    visualizarFatura,
    downloadMultiploFaturas,
    downloadFatura,
    formatDate,
    capitalizeFirstLetter,
    solicitarAtualizacao,
    formatMoney,
    closePdf(){
      this.pdfBase64 = false
    }
  },
  components:{
    PdfViewer
  }
}
</script>

<style scoped>
.download-multiplo{
  float: right;
}
</style>