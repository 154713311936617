export default {
    columns: [
        { key: 'fat_data_emissao', label: 'Referência', sortable: false, alignHead: 'center', align: 'center' , verticalAlign: 'middle', },
        { key: 'fat_data_vencimento', label: 'Vencimento', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'fat_valor', label: 'Valor', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'fat_pago', label: 'Pago', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'visualizar_id', label: 'Visualizar', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'download_id', label: 'Baixar', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'atualizacao_id', label: 'Solicitar Atualização', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
    ],
    columnsAdmin: [
        { key: 'fat_data_emissao', label: 'Referência', sortable: false, alignHead: 'center', align: 'center' , verticalAlign: 'middle', },
        { key: 'fat_data_vencimento', label: 'Vencimento', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'fat_valor', label: 'Valor', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'fat_pago', label: 'Pago', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'visualizar_id', label: 'Visualizar', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'download_id', label: 'Baixar', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'atualizacao_id', label: 'Deletar', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'marcar_id', label: 'Marcar', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
    ]
}