<template>
  <div class="row justify--center">
    <div class="flex xs12 sm12 md12 lg12 xl12">
      <va-card  class="flex mb-4" title="Minhas certidões">
        <va-card-title>
          <div v-if="$Auth.isAdmin()" style="text-align: center; width: 100%">
            <va-button icon-right="add_circle" color="secondary" gradient @click="$router.push({ name: 'admin-impostos-new' });">
              Enviar imposto
            </va-button>
          </div>
        </va-card-title>
        <va-card-content>
          <va-data-table 
            :no-data-html="'Nenhum imposto'"
            :items="items" 
            :columns="columns" 
            :animated="true" 
            :hoverable="true" 
            :striped="true"
          >
            <template #cell(imp_validade)="{ source: imp_validade }">
              {{ formatDate(imp_validade, 'YYYY-MM-DD','L') }}
            </template>
            <template #cell(imp_valor)="{ source: imp_valor }">
              {{ formatMoney(imp_valor)  }}
            </template>
            <template #cell(imp_pago)="{ source: imp_pago }">
              <va-icon
                name="thumb_up"
                size="large"
                :color="imp_pago ? 'success' : 'danger'"
                :flip="imp_pago ? 'off' : 'horizontal'"
              />
            </template>
            <template #cell(imp_recibo)="{ source: imp_recibo }">
              <va-button v-if="imp_recibo" icon="cloud_download" gradient @click="downloadImposto(imp_recibo, 'recibo')"></va-button>
              <div v-else>n/a</div>
            </template>
            <template #cell(imp_declaracao)="{ source: imp_declaracao }">
              <va-button v-if="imp_declaracao" icon="cloud_download" gradient @click="downloadImposto(imp_declaracao, 'declaracao')"></va-button>
              <div v-else>n/a</div>
            </template>
            <template #cell(imp_imposto)="{ source: imp_imposto }">
              <va-button v-if="imp_imposto" icon="cloud_download" gradient @click="downloadImposto(imp_imposto, 'imposto')"></va-button>
              <div v-else>n/a</div>
            </template>
            <template #cell(imp_parcelamento)="{ source: imp_parcelamento }">
              <va-button v-if="imp_parcelamento" icon="cloud_download" gradient @click="downloadImposto(imp_parcelamento, 'parcelamento')"></va-button>
              <div v-else>n/a</div>
            </template>
            <template #cell(atualizacao_id)="{ source: atualizacao_id }">
              <va-button icon-right="autorenew" color="secondary" gradient @click="solicitarAtualizacao(atualizacao_id)">Solicitar</va-button>
            </template>
            <template #cell(deletar_id)="{ source: deletar_id }">
              <va-button icon-right="autorenew" color="danger" gradient @click="deleteImposto(deletar_id, this)">Excluir</va-button>
            </template>
            <template #cell(update_id)="{ source: update_id }">
              <va-button icon-right="autorenew" color="warning" gradient @click="$router.push({ name: 'admin-impostos-update', params: {id: update_id }});">Editar</va-button>
            </template>
          </va-data-table>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
import ImpostosTable from "./ImpostosTable";
import {formatDate,formatMoney} from "@/functions/Formatters";
import {deleteImposto, downloadImposto, getImpostos, solicitarAtualizacao} from './ImpostosFunctions';

export default {
  name: "Impostos",
  async mounted() {
    await getImpostos(this);
  },
  data() {
    return {
      columns: this.$Auth.isAdmin() ? ImpostosTable.columnsAdmin : ImpostosTable.columns,
      items: []
    }
  },
  methods: {
    deleteImposto,
    downloadImposto,
    solicitarAtualizacao,
    formatDate,
    formatMoney
  }
}
</script>

<style scoped>

</style>