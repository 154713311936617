import {bytesToMegaBytes} from "@/functions/FileFunctions";

export default [
    [
        {
            col: 12,
            name: "bal_ano",
            value: "",
            type: "number",
            placeholder: "Ex.: 2021",
            label: "Ano do balanço",
            required: true,
            validateFunc(e: any){
               if(!parseInt(e) || parseInt(e) < 1990 || parseInt(e) > 2099){
                   return 'Informe um ano valido. Ex.: 2021'
               }

                return true;
            }
        },
    ],
    [
        {
            col: 12,
            name: "bal_balanco",
            value: "",
            type: "va-file-upload",
            label: "Balanço",
            multiple: false,
            required: true,
            validateFunc(e: any){
                if(Array.isArray(e) && e.length > 0){
                    if(e[0].type !== 'application/pdf'){
                        return 'Somente PDF';
                    }
                    if(bytesToMegaBytes(e[0].size) > 2){
                        return 'Tamanho máximo: 2MB';
                    }
                }

                return true;
            }
        }
    ]
]