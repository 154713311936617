import {FetchWs} from "@/functions/FetchWs";
import {downloadBase64File} from "@/functions/FileFunctions";

export async function downloadCertificado(): Promise<void> {
    const response = await FetchWs('certificados/get-certificado/baixar');
    if (response && response.success && response.data && response.data.base64) {
        //@todo ext name
        downloadBase64File(response.data.base64, 'cert')
    }
}

export async function solicitarCertificado(): Promise<void> {
    await FetchWs('certificados/solicitar-certificado', 'POST');
}

export async function getCertificado(vue: any): Promise<void>
{
    vue.certificado = false;
    const response = await FetchWs('certificados/get-certificado/infos');
    if(response && response.success && response.data){
        vue.certificado = response.data
        vue.certificado.is_valid = checkIfIsValid(vue.certificado.dig_validade)
    } else {
        vue.certificado = 'n/a'
    }
}

function checkIfIsValid(cer_validade: string|false)
{
    if(!cer_validade) {return false;}
    const date = new Date(cer_validade);
    const today = new Date();
    today.setHours(0,0,0,0);
    return date >= today;
}

export async function submitFormNew(data: any, vue: any)
{
    const body = new FormData();
    body.append("validade", data.cer_validade);
    body.append("certificado", data.cer_certificado[0])
    const response = await FetchWs('certificados/new', 'POST', body);
    if(response && response.success){
        vue.$router.back();
    }
}