import {ContabilidadeInterfaces} from "@/pages/painel/contabilidade/ContabilidadeInterfaces";
import {FetchWs} from "@/functions/FetchWs";
import {base64ArrayFilesToZipAndDownload, downloadBase64File} from "@/functions/FileFunctions";

export async function downloadBalanco(id: number): Promise<void> {
    const response = await FetchWs('balancos/get-balanco/' + id);
    if (response && response.success && response.data && response.data.base64) {
        downloadBase64File(response.data.base64, 'Balanço')
    }
}

export async function downloadMultiploContabilidade(contabilidades: Array<any>): Promise<void> {
    const cont_ids: string[] = contabilidades.map(con => `ids[]=${con.bal_id}&`)
    const response = await FetchWs('balancos/get-balancos?' + cont_ids.join(''));
    if (response && response.success && Array.isArray(response.data)) {
        base64ArrayFilesToZipAndDownload(response.data.map((e: any) => {return {
            name: e.nome,
            base64: e.base64,
        }}),'contabilidade.zip');
    }
}

export async function solicitarBalanco(): Promise<void> {
    await FetchWs('balancos/solicitar-balanco', 'POST');
}

export async function getBalancos(vue: any): Promise<void>
{
    vue.items = [];
    const response = await FetchWs('balancos/get-all');
    if(response && response.success && response.data.length > 0){
        vue.items = assertBalancos(response.data);
    }
}

function assertBalancos(objs: ContabilidadeInterfaces[]): ContabilidadeInterfaces[]
{
    objs.forEach((value:ContabilidadeInterfaces,key:number) => {
        objs[key].download_id = value.bal_id
        objs[key].visualizar_id = value.bal_id
        objs[key].delete_id = value.bal_id
    })
    return objs;
}

export async function visualizarBalanco(id: number, vue: any): Promise<void> {
    const response = await FetchWs('balancos/get-balanco/' + id + '?visualizar=true');
    if (response && response.success && response.data && response.data.base64) {
        vue.pdfBase64 = response.data.base64
    }
}


export async function submitFormNew(data: any, vue: any)
{
    const body = new FormData();
    body.append("ano", data.bal_ano);
    body.append("balanco", data.bal_balanco[0])
    const response = await FetchWs('balancos/new', 'POST', body);
    if(response && response.success){
        vue.$router.back();
    }
}

export async function deleteBalanco(id: number, vue: any)
{
    const response = await FetchWs(`balancos/delete/${id}`, 'DELETE');
    if(response && response.success){
        await getBalancos(vue)
    }
}