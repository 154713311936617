import {DocumentoInterface} from "@/pages/painel/documentos/DocumentosInterfaces";
import {FetchWs} from "@/functions/FetchWs";
import {base64ArrayFilesToZipAndDownload, downloadBase64File} from "@/functions/FileFunctions";

export async function downloadDocumento(id: number): Promise<void>
{
    const response = await FetchWs('documentos/get-document/' + id);
    if(response && response.success && response.data && response.data.base64){
        downloadBase64File(response.data.base64,response.data.doc_nome + '.' + response.data.extension)
    }
}

export async function downloadMultiploDocumentos(documentos: Array<any>): Promise<void>
{
    const doc_ids: string[] = documentos.map(doc => `ids[]=${doc.doc_id}&`)
    const response = await FetchWs('documentos/get-documents?' + doc_ids.join(''));
    if (response && response.success && Array.isArray(response.data)) {
       base64ArrayFilesToZipAndDownload(response.data.map((e: any) => {return {
           name: e.nome,
           base64: e.base64,
       }}),'documentos.zip');
   }
}

export async function getDocumentos(vue: any): Promise<void>
{
    vue.items = [];
    const response = await FetchWs('documentos/get-all');
    if(response && response.success && response.data.length > 0){
        vue.items = assertItems(response.data);
    }
}

export async function visualizarDocumento(id: number, vue: any): Promise<void>
{
    const response = await FetchWs('documentos/get-document/' + id + '?visualizar=true');
    if(response && response.success && response.data && response.data.base64){
        vue.pdfBase64 = response.data.base64
    }
}

export async function submitFormNew(data: any, vue: any)
{
    const body = new FormData();
    body.append("nome", data.doc_nome);
    body.append("validade", data.doc_validade);
    body.append("documento", data.doc_documento[0])
    const response = await FetchWs('documentos/new', 'POST', body);
    if(response && response.success){
        vue.$router.back();
    }
}

export async function deleteDocumento(id: number, vue: any)
{
    const response = await FetchWs(`documentos/delete/${id}`, 'DELETE');
    if(response && response.success){
        await getDocumentos(vue)
    }
}

export async function solicitarAtualizacao(id: number): Promise<void>
{
    await FetchWs('documentos/solicitar-atualizacao/' + id, 'POST');
}

function assertItems(objs: DocumentoInterface[]): DocumentoInterface[]
{
    objs.forEach((value,key) => {
        objs[key].actions = value.doc_id
        objs[key].atualizacao_id = value.doc_id
        objs[key].visualizar_id = value.doc_id
        objs[key].is_valid = checkIfIsValid(value.doc_validade)
        if (value.doc_validade === '0000-00-00'){
            objs[key].doc_validade = undefined
            objs[key].is_valid = undefined
        }
    })
    return objs;
}

function checkIfIsValid(doc_validade: string|undefined)
{
    if(!doc_validade) {return false;}
    const date = new Date(doc_validade);
    const today = new Date();
    today.setHours(0,0,0,0);
    return date >= today;
}
