import {FetchWs} from "@/functions/FetchWs";
import {getCliente} from "@/pages/painel/clientes/ClientesFunctions";
import {getDadosFromCnpj} from "@/functions/Cnpj.ws";

export async function getImpostos(vue: any): Promise<void>
{
    vue.impostosTable.items = [];
    const response = await FetchWs('impostos/get-all');
    if(response && response.success && response.data.length > 0){
        vue.impostosTable.items = response.data;
    }
}
export async function getFaturas(vue: any): Promise<void>
{
    vue.faturasTable.items  = [];
    const response = await FetchWs('faturas/get-all');
    if(response && response.success && response.data.length > 0){
        vue.faturasTable.items = response.data;
    }
}

export async function getDados(vue: any): Promise<void>
{
    const force_empresa = localStorage.getItem('ADMIN_EMPRESA_ATUAL');
    let cnpj = vue.$Auth.getProp('cnpj')
    if(vue.$Auth.checkPerm('adminForceEmpresa') && force_empresa){
        const cliente = await getCliente(parseInt(force_empresa))
        cnpj = cliente.usu_cnpj
    }

    const response = await getDadosFromCnpj(cnpj);
    if(response && response.cnpj_raiz ){
        vue.dados = response;
        montaFormDados(vue);
    }
}

export async function getLogo(vue: any): Promise<void>
{
    vue.logo  = false;
    const response = await FetchWs('user/get-foto');
    if(response && response.success && response.data.base64){
        vue.logo = response.data.base64;
    }
}

export function montaFormDados(vue: any){
    vue.formDados = {
        data: {
            readonly: true
        },
        struct: [
            [
                {
                    col: 6  ,
                    name: "razao_social",
                    value: vue.dados.razao_social,
                    type: "text",
                    label: "Razão Social",
                    readonly: true
                },
                {
                    col: 6  ,
                    name: "nome_fantasia",
                    value: vue.dados.estabelecimento?.nome_fantasia,
                    type: "text",
                    label: "Nome Fantasia",
                    readonly: true
                }
            ],
            [
                {
                    col: 4 ,
                    name: "CNPJ",
                    value: vue.dados.estabelecimento?.cnpj,
                    type: "text",
                    label: "CNPJ",
                    readonly: true,
                    mask:{
                        blocks: [2, 3, 3, 4, 2],
                        delimiters: [".", ".", ".", "-"]
                    }
                },
                {
                    col: 4  ,
                    name: "natureza_juridica",
                    value: vue.dados.natureza_juridica?.descricao,
                    type: "text",
                    label: "Natureza Juridica",
                    readonly: true
                },
                {
                    col: 4 ,
                    name: "simples",
                    value: vue.dados.simples?.simples ? vue.dados.simples?.simples : 'Não',
                    type: "text",
                    label: "Simples",
                    readonly: true
                }
            ],
        ]
    };
}