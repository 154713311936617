<template>
  <form @submit.prevent="onSubmit">
    <monta-row
      v-for="row in form.struct"
      :key="row.name"
      :all="data"
      :row="row"
      ref="rowRef"
      @update-input="updateInput"
    />
    <div v-if="!form?.data?.readonly" class="d-flex justify--center mt-3">
      <va-button type="submit" class="my-0">{{ form?.data?.submitName ? form?.data?.submitName : 'Enviar' }}</va-button>
    </div>
  </form>
</template>

<script>
import MontaRow from "@/components/form/MontaRow";
import {COLOR_THEMES} from "../../services/vuestic-ui/themes";
import Swal from 'sweetalert2'

export default {
  name: "MontaForm",
  components: {MontaRow},
  props: [
    'form'
  ],
  data() {
    return {
      data: {},
      validations: {}
    }
  },
  methods: {
    updateInput(input) {
      this.data[input.name] = input.val
      this.validations[input.name] = input.valid
    },
    updateAllInputs() {
      Object.entries(this.$refs.rowRef).forEach(([row]) => {
        this.$refs.rowRef[row].updateAllInputs();
      });
    },
    allIsValid() {
      let isValid = true;
      Object.entries(this.validations).forEach(([key]) => {
        if(!this.validations[key]) {isValid = false}
      });
      return isValid
    },
    onSubmit() {
      this.updateAllInputs();
      if (this.allIsValid()) {
        this.$emit('complete',  this.data)
        return true
      } else {

        Swal.fire({
          title: 'Verifique os dados e tente novamente',
          toast: true,
          background:  COLOR_THEMES[0].colors.danger,
          color: '#fff',
          timer: 8000,
          showConfirmButton: false,
          timerProgressBar: true,
          showCloseButton: true,
          allowOutsideClick: true,
          allowEscapeKey: true,
          allowEnterKey: true,
          position: 'top-end',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          },
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        return false
      }
    }
  }
}
</script>

<style scoped>

</style>
